import { Button, Dialog, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { REGEX_POSITIVE_INTEGER_OR_EMPTY } from "../definitions";
import { callEndpoint } from "../util";
import {
  CreateClearanceEndpoint,
  CreateTransactionEndpoint,
  Kasse1Email,
} from "@ckal-software/ckal-lib/dist/apps/kasse1";

interface EditModalProps {
  credit: number;
  user: Kasse1Email;
  refresh(): void;
  onClose(): void;
}

export function TransferModal(props: EditModalProps) {
  const [value, setValue] = useState("");
  const [isTransferring, setIsTransferring] = useState(false);

  useEffect(() => setValue(`${props.credit}`), [props.credit]);

  async function createClearance() {
    const [err] = await callEndpoint(CreateClearanceEndpoint, undefined);

    if (err) {
      alert(`Der skete en fejl: ${err.error}`);
      return;
    }

    props.refresh();
  }

  async function transfer() {
    setIsTransferring(true);

    if (Number(value) === props.credit) {
      await createClearance();
      props.refresh();
      props.onClose();
      setIsTransferring(false);

      return;
    }

    const [err] = await callEndpoint(CreateTransactionEndpoint, {
      user: props.user,
      amount: -Number(value),
      description: "Overførsel",
    });
    setIsTransferring(false);

    if (err) {
      alert(`Der skete en fejl: ${err.error}`);
      return;
    }

    props.onClose();
    props.refresh();
  }

  const invalid = useMemo(
    () => value === "" || Number(value) > props.credit || Number(value) < 0,
    [props.credit, value]
  );

  return (
    <Dialog onClose={props.onClose} open fullWidth>
      <div style={{ padding: 24 }}>
        <div style={{ marginBottom: 24, color: "rgba(0, 0, 0, 0.7)" }}>
          Der mangler at blive overført {props.credit} kr.
        </div>
        <TextField
          label="Overførsel"
          type="number"
          value={value}
          onChange={(e) => {
            if (!REGEX_POSITIVE_INTEGER_OR_EMPTY.test(e.target.value)) {
              return;
            }
            setValue(e.target.value);
          }}
          multiline
          autoFocus
          style={{ width: "100%" }}
        />
        <div
          style={{
            display: "flex",
            marginTop: 16,
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            onClick={transfer}
            disabled={isTransferring || invalid}
          >
            {isTransferring ? "..." : "OK"}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
