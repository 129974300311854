import { useState } from "react";
import { FaBackspace } from "react-icons/fa";
import { Button, CircularProgress, TextField } from "@mui/material";
import {
  EMAIL_CASPER,
  EMAIL_CECILIE,
  EMAIL_TO_USER,
  Email,
} from "../definitions";
import { callEndpoint } from "../util";
import {
  CreateTransactionEndpoint,
  Transaction,
} from "@ckal-software/ckal-lib/dist/apps/kasse1";
import { OmitAC } from "@ckal-software/ckal-lib";

interface KeypadProps {
  email: Email;
  addOptimisticTransaction(
    transaction: OmitAC<
      Transaction,
      "_id" | "addedBy" | "timestamp" | "pending"
    >
  ): void;
  refresh(): void;
}

const CellStyle = {
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export function Keypad(props: KeypadProps) {
  const [amount, setAmount] = useState("");
  const [selectedUser, setSelectedUser] = useState(props.email);
  const [description, setDescription] = useState("");
  const [isCreating, setIsCreating] = useState(false);

  function pressNumber(number: string) {
    if (number === "0" && amount === "") {
      return;
    }

    if (amount.length >= 4) {
      return;
    }

    setAmount((curr) => curr + number);
  }

  function clearNumber() {
    setAmount((curr) => curr.slice(0, -1));
  }

  async function createTransaction() {
    if (amount.length === 0) {
      alert("Indtast venligst beløb");
      return;
    }

    if (
      selectedUser !== props.email &&
      !window.confirm(`Tilføj udgift for ${EMAIL_TO_USER[selectedUser]}`)
    ) {
      return;
    }

    const transaction = {
      user: selectedUser,
      amount: Number(amount),
      description,
    };

    props.addOptimisticTransaction(transaction);

    const [err] = await callEndpoint(CreateTransactionEndpoint, transaction, {
      setWorking: setIsCreating,
    });

    if (err) {
      alert(`Der skete en fejl: ${err.error}`);
      return;
    }

    setAmount("");
    setDescription("");
    setSelectedUser(props.email);
    props.refresh();
  }

  return (
    <div
      style={{
        color: "rgba(0,0,0,0.7)",
        position: "fixed",
        bottom: 64,
        width: "calc(100vw - 16px)",
        marginLeft: 8,
      }}
    >
      <div
        style={{
          marginBottom: 120,
          textAlign: "center",
          marginTop: 48,
          fontSize: 50,
          display: "grid",
          alignItems: "center",
          justifyItems: "center",
          gridTemplateColumns: "1fr 1fr 1fr",
        }}
      >
        <div />
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>{amount}</div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className="blinking-cursor">|</span>
          </div>
          <div>kr.</div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: 24,
          }}
        >
          {amount.length > 0 && (
            <FaBackspace style={{ fontSize: 30 }} onClick={clearNumber} />
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          columnGap: 16,
          justifyContent: "center",
          marginBottom: 8,
        }}
      >
        <Button
          variant="outlined"
          size="small"
          onClick={() => setDescription("Mad")}
        >
          Mad
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={() => setDescription("Snacks")}
        >
          Snacks
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={() => setDescription("Fritid")}
        >
          Fritid
        </Button>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <TextField
          label="Beskrivelse"
          variant="standard"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          style={{ marginBottom: 8, width: "calc(100% - 36px)" }}
        />
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gridAutoRows: 60,
          fontSize: 24,
          fontWeight: 500,
          alignItems: "center",
          justifyItems: "center",
          background: "white",
          borderRadius: 8,
          border: "1px solid rgba(0,0,0,0.1)",
        }}
      >
        <div style={CellStyle} onClick={() => pressNumber("1")}>
          1
        </div>
        <div style={CellStyle} onClick={() => pressNumber("2")}>
          2
        </div>
        <div style={CellStyle} onClick={() => pressNumber("3")}>
          3
        </div>
        <div style={CellStyle} onClick={() => pressNumber("4")}>
          4
        </div>
        <div style={CellStyle} onClick={() => pressNumber("5")}>
          5
        </div>
        <div style={CellStyle} onClick={() => pressNumber("6")}>
          6
        </div>
        <div style={CellStyle} onClick={() => pressNumber("7")}>
          7
        </div>
        <div style={CellStyle} onClick={() => pressNumber("8")}>
          8
        </div>
        <div style={CellStyle} onClick={() => pressNumber("9")}>
          9
        </div>
        <div
          style={{ ...CellStyle }}
          onClick={() =>
            setSelectedUser((curr) =>
              curr === EMAIL_CASPER ? EMAIL_CECILIE : EMAIL_CASPER
            )
          }
        >
          {EMAIL_TO_USER[selectedUser]}
        </div>
        <div style={CellStyle} onClick={() => pressNumber("0")}>
          0
        </div>
        <div style={CellStyle}>
          <div
            style={{
              padding: "4px 20px",
              borderRadius: 50,
              background: "#3089f9",
              color: "white",
              width: 32,
              textAlign: "center",
            }}
            onClick={createTransaction}
          >
            {isCreating ? (
              <CircularProgress
                color="inherit"
                size={20}
                style={{ marginBottom: -2, color: "white" }}
              />
            ) : (
              "OK"
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
